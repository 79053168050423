html, body, #app {
  height: 100%;
}
body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

.chart path {
  fill: none;
  stroke: #000;
  stroke-width: 1.5px;
}

 .Resizer {
        background: #000;
        opacity: .2;
        z-index: 1;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background-clip: padding-box;
    }

     .Resizer:hover {
        -webkit-transition: all 2s ease;
        -o-transition: all 2s ease;
        transition: all 2s ease;
    }

     .Resizer.horizontal {
        height: 11px;
        margin: -5px 0;
        border-top: 5px solid rgba(255, 255, 255, 0);
        border-bottom: 5px solid rgba(255, 255, 255, 0);
        cursor: row-resize;
        width: 100%;
    }

    .Resizer.horizontal:hover {
        border-top: 5px solid rgba(0, 0, 0, 0.5);
        border-bottom: 5px solid rgba(0, 0, 0, 0.5);
    }

    .Resizer.vertical {
        width: 21px;
        margin: 0 -5px;
        border-left: 5px solid rgba(255, 255, 255, 0);
        border-right: 5px solid rgba(255, 255, 255, 0);
        cursor: col-resize;
    }

    .Resizer.vertical:hover {
        border-left: 5px solid rgba(0, 0, 0, 0.5);
        border-right: 5px solid rgba(0, 0, 0, 0.5);
    }
    .Resizer.disabled {
      cursor: not-allowed;
    }
    .Resizer.disabled:hover {
      border-color: transparent;
    }

.custom.cy-context-menus-cxt-menu{
    display:none;
    z-index:1000;
    position:absolute;
    /*border:1px solid #A0A0A0;*/
    border:0 !important;
    padding: 0;
    margin: 0;
    width: 300px;
    border-radius: 8px;
    background-color: #ffffffcc;
}
.custom.cy-context-menus-cxt-menuitem{
    font-size: 20px;
    font-weight: bold;
    margin: 8px;
    background-color: transparent;
    width: 284px;
}

.custom.cy-context-menus-cxt-menuitem:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #0277bd;
    background-image: none;
    cursor: pointer;
}

.tag input, .tag-editor, .tag-editor-placeholder, .tag-editor-mirror {
  font-size: 1rem;
  font-family: Helvetica, Arial, sans-serif; }

.tag input, .tag-editor-placeholder {
  padding: 1px 2em 1px 0.6em;
  line-height: 1.8; }

.tag {
  display: inline-block;
  margin-right: .3em;
  margin-bottom: .5em;
  position: relative; }
  .tag input {
    margin: 0;
    border: 0;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    color: #222;
    background-color: #cee7f9;
    min-width: 1em;
    border-radius: 4px; }
    .tag input:focus {
      color: #111;
      background-color: transparent;
      outline: 0; }
      .tag input:focus + a {
        display: none; }
  .tag a {
    position: absolute;
    top: 50%;
    right: 0.3em;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    border-radius: 50%;
    cursor: pointer;
    color: #222;
    line-height: 1;
    font-size: 1.231em;
    background: #fff;
    width: 1em;
    height: 1em;
    text-align: center; }
    .tag a:hover {
      color: #111; }
  .tag.has-error input {
    background-color: #f2c9c9; }
  .tag.has-error input,
  .tag.has-error a {
    -webkit-transition: color 1s;
    -o-transition: color 1s;
    transition: color 1s;
    color: #5c0909; }

.tag-editor {
  padding: .5em .5em 0;
  border: 1px solid #ccc;
  cursor: text; }
  .tag-editor.is-active {
    border-color: #808080; }

.tag-editor-placeholder {
  color: #777;
  margin-bottom: .5em; }

.tag-editor-mirror {
  position: absolute;
  top: -9999px;
  left: -9999px;
  width: auto;
  visibility: hidden; }

.react-tagsinput {
  height: 200px;
}


.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    -webkit-transition: opacity 250ms ease !important;
    -o-transition: opacity 250ms ease !important;
    transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}

.react-contextmenu-wrapper {
  float: left;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
	cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #878a8c;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
	padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "\25B6";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.testBadge{
  background-color: #332244;
}

.react-tagsinput {
  height: 80px;
}

.scenario-modal {
  height: 600px;
}

.ld-outcomes-modal {
  width: 1080px !important;
  max-width: 1080px !important;
}

.ld-outcomes-modal .card-body{
  height: 300px;
}