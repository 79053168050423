body{
  background-color: #ffffff !important;
  background-image: url(https://resources.ideab3.it/docs/idea-loghi.png);
  background-repeat: no-repeat;
  background-position: center 95%
}

.App {
  height: 100%;
  min-height: 400px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
}

.App-flex {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.App-heading {
  background-color: #222;
  color: #f8f8f8;
  font-size: 6vh;
  -webkit-box-shadow: 0px 4px 4vh 4px rgba(34,34,34,0.9);
          box-shadow: 0px 4px 4vh 4px rgba(34,34,34,0.9);
  z-index: 2;
}

.App-react {
  color: #00d8ff;
  text-decoration: overline underline;
}

.App-logo {
  max-height: 30vh;
  max-width: 30vh;
}

.App-instructions {
  background-color: #f8f8f8;
  color: #222;
  font-size: 3vh;
  line-height: 1.5;
  padding: 0 1em;
}

.App-instructions code {
  background-color: #222;
  color: #00d8ff;
  padding: .2em .3em;
  border-radius: .2em;
}
